@font-face {
	font-family: "poppins";
	src: url(../../assets/poppins/Poppins-Bold.ttf);
}

.pagina-productos-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5em;
    /* width: 100%; */
    padding: 1em;
    background-color: var(--white-color);
    padding-bottom: 3em;
}

.pagina-productos-container button{
    background-color: transparent;
    border: none;
    padding-left: 0.3em;
}
.pagina-productos-container  p{
    color: var(--grey-color);
}
.pagina-productos-container ul{
    list-style: none;
    padding-left: 1em;
    
}
.pagina-productos-container li:hover input{
    /* list-style:disc; */
    font-weight: bold;
}
li::marker{
    color: var(--icon-color);
}

.pagina-productos-container h2{
    margin-bottom: 1em;
    font-family: "poppins";
    font-weight: bold;
}
.pagina-productos-container .filtro-titulo{
    color: var(--icon-color);
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 2em;
}
.filtros-container{
    width: 30%;
    padding-top: 10px;
    padding-left: 12em;
    padding-right: 8em;
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.filtros-container .filtro-precio{
    position: relative;
}
.filtros-container .categorias{
    position: relative;
    width: 300px;
    height: 200px;
    overflow: auto;
    scrollbar-width: none;
}

@media screen and (max-width: 1900px ) {
    .filtros-container{
        padding-top: 0;
        padding-left: 0; 
    }
}

.filtros-container li{
    padding-bottom: 1em;
    margin-bottom: 1em;
    cursor: pointer;
    /* max-width: 181px; */
    
}

.filtros-container li input{
    border: none;
    color: var(--grey-color);
    cursor: pointer;
    /* max-width: 181px; */
    position: absolute;
}

/* .filtros-container .categorias{
    margin-top: 3em;
} */

.pagina-productos .productos{
    display: grid;
    /* grid-auto-rows: 20em; */
    grid-template-columns: repeat(auto-fill, minmax(15.5em, 1fr));
    /* grid-row-gap: 4em; */
    /* gap: 1em; */
    justify-items: center;
    align-items: center;
    margin-top: 2em;
    /* width: 70%; */
}

.pagina-productos .productos button{
    border: none;
    background-color: transparent;
}
.pagina-productos-container .button-container{
    padding-left: 1em;
    display: flex;
    /* width: 20%; */
    align-items:flex-end;
    justify-content: center;
}
.pagina-productos-container .cart-button{  
    background-color: var(--icon-color);
    color: var(--white-color);
    border: none;
    width: 10em;
    padding: 0.5em;
    margin-bottom: 1em;
    margin-top: 1em;
    fill: var(--white-color);
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.5em;
}

