@font-face {
	font-family: "roboto";
	src: url(../../assets/Roboto/Roboto-Bold.ttf);
}
@font-face {
	font-family: "poppins";
	src: url(../../assets/poppins/Poppins-Bold.ttf);
}
.main{
    padding: 1em;
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* padding-top: 80px; */
    width: 100%;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: var(--white-color);
}
.main p{
    font-size: 1.2em;
}

.main section{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    
}
.main .section-header{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    padding-left: 15em;
    margin-bottom: 3em;
}
@media screen and (min-width:1440px) {
    .main .section-header{
        padding-left: 4em;
    }
}
.main .section-area{
    color: var(--icon-color);
    text-transform:uppercase;
    font-weight: bold;
    /* font-family: "roboto",Arial, Helvetica, sans-serif; */
}

.main .section-title{
    color: var(--secondary-color);
    font-family: "poppins";
}

.main button{
    border: none;
    padding: 1em;
    background-color: transparent;
}

.main .button{
    background-color: var(--icon-color);
    color: var(--primary-color);
    border: none;
    padding: 1em;
    font-weight: bold;
}

.main .ver-mas{
    background-color: var(--icon-color);
    color: var(--primary-color);
    border: none;
    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    margin: 1em;
    margin-top: 5em;
    margin-bottom: 3em;
    font-weight: bold;
}
.main .blue-button{
    background-color: #0583F2;
    border-radius: 1em;
    color: var(--primary-color);
}


.home-carrusel{
    width: 600px;
    height: 500px;
    margin-top: 0;
}

.divisiones{
    /* border: 2px red solid; */
}
.seleccion{
    /* border: 2px red solid; */
}
.registro{
    /* border: 2px red solid; */
    background-color: var(--background-color);
    width: 100%;
}
.promos{
    /* border: 2px red solid; */
}
.esenciales{
    /* border: 2px red solid; */
}