@font-face {
	font-family: "poppins-reg";
	src: url(../../assets/poppins/Poppins-Regular.ttf);
}
@font-face {
	font-family: "poppins-bold";
	src: url(../../assets/poppins/Poppins-Bold.ttf);
}
@font-face {
    font-family: "roboto-reg";
    src: url(../../assets/Roboto/Roboto-Regular.ttf);
}
@font-face {
    font-family: "roboto-medium";
    src: url(../../assets/Roboto/Roboto-Medium.ttf);
}
.menu-vertical{
	justify-content:flex-start;
    align-items: center;
    text-align: start;
    min-height: 800px;
	width: 250px;
    padding: 0;
	background-color: var(--nav-color);
    color: var(--primary-color);
    border-radius: 1em 0 0 1em;
	margin-right: 5em;
	left: -6em;
    list-style: none;
    padding-bottom: 10em;
    padding-top: 1em;
}
/* .menu-vertical-title{
    margin-bottom: 10em;
} */

.menu-vertical .category-section{
    font-family: "roboto-reg";
    font-size: 12pt;
}
.menu-vertical .category-section:hover{
    font-family: "roboto-reg";
    font-size: 12pt;
}

.triangulo-equilatero {
     width: 0;
     height: 0;
     border-right: 10px solid transparent;
     border-top: 10px solid transparent;
     border-left: 10px solid transparent;
     border-bottom: 10px solid var(--secondary-color);
}
.menu-vertical li{
    width: 100%;
    padding: 1em;
    padding-left: 2em;
}
.menu-vertical li:hover{
    background-color: var(--green-color);
}
.menu-vertical .category-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menu-vertical .category-section:hover{
    text-decoration: none;
    border-bottom: none;
    color: var(--white-color);
}
.menu-vertical h2{
    font-size: 1em;
    padding: 1em;
}
.menu-final{
	position: absolute;
	top: 0;
	left: 250px;
	/* margin-left: 20px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between; 
    height: 100%;
    background-color: var(--white-color);
	color: var(--secondary-color);
    padding: 1em;
    border-radius: 0 1em 1em 0;
    
}

.menu-final .parent-category{
    font-family: "poppins-bold";
    font-size: 18pt;
}
.menu-final .grid-container{
    width: 500px;
    margin-right: 3em;
    display: flex;
    flex-wrap: wrap;
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(5em, 1fr));
    grid-auto-rows: 20em; */
    /* justify-items: stretch;
    align-items: stretch; */
}
.menu-final h2{
    padding-left: 0;
    padding-bottom: 1em;
    border-bottom: solid 1px var(--border-bottom-color);
    font-size: 1.5em;
}
.menu-final h3{
    font-size: 1em;
    margin-top: 0.5em;
    font-weight: bold;
    font-family: "roboto-medium";
    /* font-size: 16pt; */
    cursor: pointer;
}

.menu-final .grid-container ul{
    padding: 0;
}

.menu-final .grid-container li{
    padding: 0;
    padding-bottom: 0.5em;
    color: var(--grey-color);
}

.menu-final li:hover{
    background-color: var(--white-color);
}

li.seccion:hover .menu-final{
    display: flex;
  } 

.menu-final .seccion li{
    font-family: "roboto-reg";
    font-size: 12pt;
}

.menu-final .seccion{
    margin-right: 1em;
}
