@font-face {
	font-family: "roboto-bold";
	src: url(../../assets/Roboto/Roboto-Bold.ttf);
}
@font-face {
	font-family: "roboto-light";
	src: url(../../assets/Roboto/Roboto-Light.ttf);
}

.slider-container{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
    
}

.slider-container .button{
    border: none;
	background: transparent;
	padding: 0;
}

.slide {
	max-width: 100%;
	height: auto;
	opacity: 0;
	transition: opacity 0.75s ease-in-out;
    overflow: hidden;
}

.slide.active {
	opacity: 1;
}

.slider-container .img-container{
    width: 100%;
    height: 100%; 
	
}

/* estilos especificos de este slider */

.img-container-5{
	margin-left: 1em;
	margin-right: 1em;
	max-width: 16em;
}
.img-container-5 img{
	width: 15em;
	/* width: 100%; */
    height: 15.6em;
	margin-bottom: 1em;
	object-fit: contain;
}

.img-container-5 .producto-info{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0.5em;
}

.img-container-5 .producto-info h3{
	font-family: "roboto-bold";
	margin-bottom: 0;
	font-size: 16pt;
	text-transform: uppercase;
	
}

.img-container-5 .producto-info p{
	font-family: "roboto-light";
	font-size: 14pt;
	color: var(--grey-color);
}
.img-container-5 .producto-info div{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.img-container-5 .producto-info button{
	padding: 0;
}

