:root {
    --primary-color: #EFEFEF;
    --white-color:#FFFFFF;
    --grey-color: #909aa1;
    --green-color: #58A69E;
    --icon-color: #F2509C;
    --secondary-color: #2B2B38;
    --nav-color: #252530;
    --background-color: #EDEDFF;
    --border-bottom-color : #E8E8E8;
    --background-color-cart: #F2F2F2;
    

  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  color: var(--nav-color);
}

a{
  text-decoration: none;
  color: var(--nav-color);
  cursor: pointer;
}
a:hover {
  color: var(--icon-color);
  text-decoration: none;
}

body{
    margin: 0;
    display: flex;
    min-width: 320px;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    background-color: var(--white-color);
}

.header-container{
/* position: absolute; */
top: 0;
width: 100%;
}

h1 {
    font-size: 3.2em;
    line-height: 1.1;
  }

a{
    text-decoration: none;
    color: var(--nav-color);
    cursor: pointer;
}
a:hover {
    color: var(--icon-color);
  }

button{
    cursor: pointer;
}

#root{
    width: 100%;
    background-color: var(--white-color);
}