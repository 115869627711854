:root{
    --Turqoise: #5cb8b2;
}

.container-promotions{
    max-width: 5000px !important;
    height: auto;
    padding: 0 !important;
}

.promotions-table-container{
    background-color: #a8b3bb;
    width: 100%;
    height: auto;
    min-height: 720px;
    padding-bottom: 50px;
    position: relative;
}

.promotions-table{
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.nav-tabs{
    max-width: 1200px !important;
    margin: 0 auto !important;
    margin-bottom: 0 !important;   
}

.nav-tabs .nav-link{
    border: 0 !important;
}

.header-container{
    background-color: white;
    width: 100%;
    /* height: 0px; */
}

.search-container{
    position: absolute;
    top: -42px;
    right: 0;
}

.search{
    width: 100%;
    min-width: 450px;
    padding: .1rem 1.5rem;
    border-radius: 5rem;
    border: 1.5px solid gray;
    outline: none;
}

.search-icon{
    position: absolute;
    right: 10px;
    bottom: 3px;
    color: var(--Turqoise);
}

main{
    overflow: hidden;
}

main section{
    margin-top: 80px;
}

.title{
    color: #363b3f;
    font-weight: bold;
    max-width: 1200px;
    margin: 0 auto;
}

.btn-primary:focus{
    box-shadow: none !important;
}

.filter{
    position: absolute;
    top: 32px;
    right: 0px;
    padding: .2rem 2rem !important;
    border: none !important;
    border-radius: 5rem !important;
    background-color: var(--Turqoise) !important;
}

.filter div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter p{
    padding: 0 !important;
    margin: 0;
    margin-left: 8px;
    text-transform: uppercase;
    font-weight: 400;
}

.promotions-table thead th{
    color: #44454e !important;
    padding-top: 2.3rem;
    padding-bottom: 1rem;
    padding-left: 0;
    font-size: .8rem;
}

.promotions-table thead th:first-child{
    padding-left: .8rem;
}

.promotions-table tbody td{
    color: #7b8593 !important;
    padding: 77px 0px;
}

.promotions-table tbody tr{
    height: 7rem;
    background-color: white;
    margin: 20px 0;
    border-bottom: 20px solid #a8b3bb !important;
}

.promotions-table tbody, .promotions-table thead, .promotions-table tr, .promotions-table td, .promotions-table thead th {
    border: none !important;
}

.img-promotion-td{
    padding: .8rem !important;
}

.img-promotion-container{
    background-color: #909aa1;
    height: 153px;
    width: 153px;
}

.p-0{
    width: 100%;
}

.container-btn-actions{
    width: 25%;
    min-width: 218px;
    max-width: 300px;
    padding: 69px 0 !important;
}

.btn-action{
    background-color: transparent !important;
    border: none !important;
    display: flex !important;
    align-items: center;
}

.btn-action p{
    color: #363b3f;
    margin: 0 .5rem;
    font-weight: 500;
}

.list-icon{
    padding: 0 !important;
}

.icon-edit{
    color: #e09244;
}

.icon-delete{
    color: red;
}

.nav-item:focus{
    border-bottom: 5px solid red;
}

.add-promotions-main-container{
    margin: 0 auto;
}

.btn-add-promotion{
    position: absolute;
    bottom: .2rem;
    left: 0;
    background-color: var(--Turqoise) !important;
    border: none !important;
    padding: .5rem 3rem !important;
    border-radius: 5rem !important;
    font-weight: bold !important;
    font-size: 1.1rem !important;
    z-index: 10;
}

.footer{
    padding: 1rem 3rem;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.footer p{
    color: #909aa1;
}

.footer strong{
    color: black;
}

.form-add-promotions{
    width: 100%;
    max-width: 800px;
}

.form-container{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    min-height: 800px;
}

.form-group{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
    justify-content: space-between;
    margin-bottom: 20px;
}

.select-container span{
    display: inline-block;
    margin-right: 100px;
    margin: 0;
}

.input{
    width: 100%;
    max-width: 600px;
}

.input-select{
    cursor: pointer;
}

.form-label{
    margin: 0 !important;
    white-space: nowrap;
}

.upload-container{
    display: flex;
    align-items: center;
    height: 38px;
}

.upload-container p{
    margin: 0;
    padding: 0 1rem;
}

.upload-file{
    display: flex;
    align-items: center;
    padding: .48rem;
    background-color: var(--Turqoise);
    width: 30%;
    cursor: pointer;
}

.upload-file span{
    display: inline-block;
    margin-left: 10px;
    color: white;
}

.plus-icon{
    color: white;
}

.file-name-container{
    background-color: white;
    width: 100%;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}